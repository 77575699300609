import React from 'react'

const FooterCallToAction = () => {
  return (

    <div className="bg-purple-700">
      <div className="mx-auto max-w-5xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-white sm:text-4xl">
          <span className="block">Ready to dive in?</span>
          <span className="block">Sign up today and get 20% cyber weekend special.</span>
        </h2>        
        <a
                href="https://app.shieldstack.io"
                className="mt-8 inline-flex items-center rounded-md border border-transparent bg-white px-5 py-4 text-base font-medium text-purple-600 shadow hover:bg-purple-50"
              >
                Get Started
              </a>
      </div>
    </div>

  )
}

export default FooterCallToAction