import React from 'react';
import { StarIcon } from '@heroicons/react/20/solid';
import { StaticImage } from "gatsby-plugin-image";

const Reviews = () => {
  return (
    <div className="relative mx-auto max-w-7xl py-14 sm:py-22 lg:py-18 px-6 sm:px-6">
        <h2 className="text-3xl mx-auto max-w-4xl text-center font-bold text-gray-900 sm:text-5xl">
            Love Form Customers
        </h2>
        <section className="overflow-hidden bg-white">          
            <div className="relative mx-auto max-w-7xl pt-20 pb-12 lg:py-20">
                
                <div className="relative lg:flex lg:items-center">
                    <div className="hidden lg:block lg:flex-shrink-0">
                        <StaticImage src="../../images/review.jpg" 
                            alt="Judith Christopher" 
                            loading="eager"
                            placeholder="none"
                            className="h-64 w-64 xl:h-80 xl:w-80"
                        /> 
                        
                    </div>

                    <div className="relative lg:ml-10">
                        
                        <blockquote className="relative">
                        <div className="text-xl sm:text-2xl font-bold text-gray-900">

                        <div className="flex flex-shrink-0 pr-5 mb-5">
                          <StarIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                        </div>

                            <p>
                            I purchased Shieldstack because my website was hacked and people were getting popups on my website. 
                            Shieldstack went to work immediately to clean this up and prevent this from happening in the future. 
                            They identified the vulnerability with in a day and get it fixed. 
                            I'm glad to be in total control of my site again! Thank you Shieldstack!
                            </p>
                        </div>
                        <div className="mt-8">
                            <div className="flex">
                            <div className="flex-shrink-0 lg:hidden">
                                <StaticImage src="../../images/review.jpg" 
                                    alt="Judith Christopher" 
                                    loading="eager"
                                    placeholder="none"
                                    className="h-12 w-12 rounded-full"
                                />                                 
                            </div>
                            <div className="ml-4 lg:ml-0">
                                <div className="text-base font-medium text-gray-900">Judith Christopher</div>
                                <div className="text-base font-medium text-indigo-600">CEO, Tuple</div>
                            </div>
                            </div>
                        </div>
                        </blockquote>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Reviews