import React from 'react'

const Trust = () => {
  return (
    <>
        <div className="mx-auto max-w-5xl pt-16 sm:pt-20 px-6 sm:px-6">
            <h2 className="text-2xl text-center text-gray-900">
                More than 50+ companies trusted us worldwide
            </h2>       
            <div className="mt-6 grid grid-cols-3 gap-0.5 md:grid-cols-3 lg:grid-cols-5 lg:mt-6">
            <div className="col-span-1 flex justify-center py-8 px-8">
                    <div className="text-center">
                        <p className="text-4xl sm:text-5xl font-bold tracking-tight text-gray-400">99.99%</p>
                        <p className="mt-2 text-lg font-medium leading-6 text-gray-600">Uptime Server</p>               
                    </div>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8">
                    <div className="text-center">
                        <p className="text-4xl sm:text-5xl font-bold tracking-tight text-gray-400">24/7</p>
                        <p className="mt-2 text-lg font-medium leading-6 text-gray-600">Monitoring</p>               
                    </div>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8">
                    <div className="text-center">
                        <p className="text-4xl sm:text-5xl font-bold tracking-tight text-gray-400">50+</p>
                        <p className="mt-2 text-lg font-medium leading-6 text-gray-600">Customers</p>               
                    </div>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8">
                    <div className="text-center">
                        <p className="text-4xl sm:text-5xl font-bold tracking-tight text-gray-400">30k+</p>
                        <p className="mt-2 text-lg font-medium leading-6 text-gray-600">Threat Blocks</p>               
                    </div>
            </div>
            <div className="col-span-1 flex justify-center py-8 px-8">
                    <div className="text-center">
                        <p className="text-4xl sm:text-5xl font-bold tracking-tight text-gray-400">5</p>
                        <p className="mt-2 text-lg font-medium leading-6 text-gray-600">Star Rating</p>               
                    </div>
            </div>          
            </div>      
        </div>
    </>

  )
}

export default Trust