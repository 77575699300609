import React from 'react';

import { useLottie } from "lottie-react";

import business from "./animation/business.json";

const AdditionalDetails = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: business,
        rendererSettings: {      
          viewBoxSize: '40 50 412 412',          
        },
    };

    const { View: businessAnimation } = useLottie(defaultOptions);

    return (
        <div className="relative mx-auto max-w-7xl pt-14 sm:pt-22 lg:pt-18 px-6 sm:px-6">
            <div className="mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl ">
                <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
                    <div>
                        <h2 className="text-3xl mx-auto max-w-4xl text-left font-bold text-gray-900 sm:text-5xl">
                            Focus on your business while we keep your site secure
                        </h2>
                        <p className="mt-6 max-w-3xl text-lg text-gray-900">
                            We got your back anything related to cyber attack on your website. Our 24x7 dedicated team always here to repair and restore hacked websites before it damages your website’s reputation. Get peace of mind by securing your websites with us and focus on your business.
                        </p>
                    </div>
                    <div className="mt-4 lg:mt-0 md:max-lg:w-3/4 md:max-lg:m-auto">
                        {businessAnimation}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdditionalDetails