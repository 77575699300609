import React from 'react';
import { useLottie } from "lottie-react";

import hosting from "./animation/hosting.json";
import security from "./animation/security.json";
import maintenance from "./animation/maintenance.json";

const Features = () => {
  
    const defaultOptionsHosting = {
      loop: true,
      autoplay: true,
      animationData: hosting,
      rendererSettings: {      
        viewBoxSize: '60 50 412 412',
      },
    };

    const defaultOptionsSecurity = {
      loop: true,
      autoplay: true,
      animationData: security,
      rendererSettings: {      
        viewBoxSize: '60 50 412 412',
      },
    };

    const defaultOptionsMaintenance = {
      loop: true,
      autoplay: true,
      animationData: maintenance,
      rendererSettings: {      
        viewBoxSize: '60 50 412 412',
      },
    };

    const { View: hostingAnimation } = useLottie(defaultOptionsHosting);
    const { View: securityAnimation } = useLottie(defaultOptionsSecurity);
    const { View: maintenanceAnimation } = useLottie(defaultOptionsMaintenance);

    return (
      <div className="relative mx-auto max-w-7xl pt-16 pb-6 sm:pt-24 sm:pb-14 lg:pt-24 lg:pb-10 px-6 sm:px-6">
        <div className="mx-auto max-w-md text-center sm:max-w-3xl lg:max-w-7xl">        
          <h2 className="text-3xl mx-auto max-w-4xl text-center font-bold text-gray-900 sm:text-5xl">
          Hosting a site, keeping it secure and maintenance we got it covered
          </h2>        
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            
                <div className="pt-6">
                  <div className=" bg-gray-100 px-6 ">                      
                      { hostingAnimation }
                  </div>
                  <div className="mt-6 text-left">                    
                      <h3 className="mt-8 text-2xl font-bold text-gray-900">High Performance Hosting</h3>
                      <p className="mt-5 text-lg text-gray-900">Host your website in a secure environment with managed updates to increase your website performance and SEO rankings.</p>
                    </div>
                </div>

                <div  className="pt-6">
                  <div className="flow-root bg-gray-100 px-6">
                      { securityAnimation }
                  </div>
                  <div className="mt-6 text-left">                    
                      <h3 className="mt-8 text-2xl font-bold text-gray-900">Website Security</h3>
                      <p className="mt-5 text-lg text-gray-900">Protect your site with advanced security, HTTPS encryption, fix malware with scanning and prevent malware with WAF Protection.</p>
                    </div>
                </div>

                <div  className="pt-6">
                  <div className="flow-root  bg-gray-100 px-6">
                      { maintenanceAnimation }
                  </div>
                  <div className="mt-6 text-left">                    
                      <h3 className="mt-8 text-2xl font-bold text-gray-900">Maintenance</h3>
                      <p className="mt-5 text-lg text-gray-900">With managed maintenance, keep your website CMS's plugins and themes up to date to keep your environments secure.</p>
                    </div>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    )
}

export default Features