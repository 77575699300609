import React from 'react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { useLottie } from "lottie-react";

import safe from "./animation/safe.json";

const Details = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: safe,
        rendererSettings: {      
          viewBoxSize: '40 50 412 412',          
        },
    };

    const { View: safeAnimation } = useLottie(defaultOptions);

    return (
        <div className="relative mx-auto max-w-7xl pt-4 pb-0 sm:pt-15 sm:pb-0 lg:pt-20 lg:pb-0 px-6 sm:px-6">
            <div className="mx-auto max-w-md sm:max-w-3xl lg:max-w-7xl ">
                <div className="lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
                    
                    <div className="mt-3 sm:mt-12 lg:mt-0 md:max-lg:w-3/4 md:max-lg:m-auto">
                        { safeAnimation }
                    </div>
                    <div>
                        <h2 className="text-3xl mx-auto max-w-4xl text-left font-bold text-gray-900 sm:text-5xl">
                            Your customers will be safe on your site.
                        </h2>
                        <p className="mt-6 max-w-3xl text-lg text-gray-900">
                            With the following services, we let your site be trustworthy and that any data the customer exchanges with your site is encrypted, keeping it safe from data theft or exploitation.
                        </p>

                        <div className="mt-6">
                            <p className="mt-2 font-medium flex items-center text-lg text-gray-900">
                                <CheckCircleIcon className="mr-2 h-7 w-7 flex-shrink-0 text-green-400" aria-hidden="true" />
                                Managed Web Application
                            </p>
                            <p className="mt-2 font-medium flex items-center text-lg text-gray-900">
                                <CheckCircleIcon className="mr-2 h-7 w-7 flex-shrink-0 text-green-400" aria-hidden="true" />
                                SIEM Threat Detection
                            </p>
                            <p className="mt-2 font-medium flex items-center text-lg text-gray-900">
                                <CheckCircleIcon className="mr-2 h-7 w-7 flex-shrink-0 text-green-400" aria-hidden="true" />
                                Website Hack Repair
                            </p>
                            <p className="mt-2 font-medium flex items-center text-lg text-gray-900">
                                <CheckCircleIcon className="mr-2 h-7 w-7 flex-shrink-0 text-green-400" aria-hidden="true" />
                                Website Application Firewall
                            </p>
                            <p className="mt-2 font-medium flex items-center text-lg text-gray-900">
                                <CheckCircleIcon className="mr-2 h-7 w-7 flex-shrink-0 text-green-400" aria-hidden="true" />
                                HTTPS encryption with an SSL
                            </p>
                        </div>
                    
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Details