import * as React from "react"
import { HeadFC } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/home/hero"
import Trust from "../components/home/trust"
import Features from "../components/home/features"
import Details from "../components/home/details"
import AdditionalDetails from "../components/home/additional-details"
import Reviews from "../components/home/reviews"
import FooterCallToAction from "../components/home/footer-call-to-action"

const IndexPage = () => {
  return (
    <Layout>
      <Hero/>
      <Trust/>
      <Features/>
      <Details/>
      <AdditionalDetails/>
      <Reviews/>
      <FooterCallToAction/>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Website Hosting, Security, Protection, Monitoring and Maintenance  | WP Engine®</title>
