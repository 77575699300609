import React from 'react';
import { StarIcon } from '@heroicons/react/20/solid';
import { useLottie } from "lottie-react";

import animation from "./animation/hero.json";

const Hero = () => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {      
      viewBoxSize: '60 50 412 412',
    },
  };

  const { View: heroAnimation } = useLottie(defaultOptions);

  return (
    <div className="mx-auto max-w-7xl px-6 sm:px-6 mt-6 sm:mt-6">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                <div>
                  <div>              
                    <div className="">
                      <h1 className="text-4xl font-bold text-gray-900 sm:text-6xl">
                        Protect your website, protect your brand.
                      </h1>
                      <p className="mt-6 text-lg text-gray-900">
                        Secure hosting, malware protection, 24x7 security monitoring, powerful website firewalls and managed maintenance for your business website.
                      </p>
                    </div>
                    <div className="mt-6 sm:mt-12 w-full">                
                      <div className="mt-0 sm:mt-0  ">
                        <a href="https://app.shieldstack.io/" className="inline-block rounded-md border border-transparent bg-purple-600 px-5 py-4 text-base font-medium text-white shadow hover:bg-purple-700 sm:px-10">
                          Get Started
                        </a>                                               
                      </div>
                    </div>
                    <div className="mt-6">
                      <div className="inline-flex items-center divide-x divide-gray-300">
                        <div className="flex flex-shrink-0 pr-5">
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                          <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                        </div>
                        <div className="min-w-0 flex-1 py-1 pl-5 text-sm text-gray-500 sm:py-3">
                          <span className="font-medium text-gray-900">Rated 5 stars</span> by over{' '}
                          <span className="font-medium text-pink-500">50+ customers</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-5  lg:col-span-6 w- lg:mt-0 md:max-lg:w-3/4 md:max-lg:m-auto">   
                 {heroAnimation}                  
              </div>
            </div>
        </div>
  )
}

export default Hero